<template>
  <div class="leiloes-lista-lotes">
    <div class="filters row m-b-xs font-11 justify-between">
      <div class="col text-grey-7" style="max-width: 130px; line-height: 20px">
        {{filteredServerData.length}} de {{table.serverPagination.rowsNumber|number}} lote{{table.serverPagination.rowsNumber > 1 ? 's' : ''}}
        <!--{{filteredServerData.length}} lote{{filteredServerData.length > 1 ? 's' : ''}}-->
        <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="table.loading" />
      </div>
      <div class="col" style="max-width: 220px">
        <status-lote-select label="Filtrar pelo status:" field-view="ll" multiple v-model="table.filters.status" />
      </div>
      <div class="col m-l-lg" style="line-height: 20px; max-width: 500px">
        <div class="row">
          <div class="col">
            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.semLance">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Sem lances</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.comLanceOnline">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Com lances online</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="flex no-wrap items-center m-r-lg" v-model="table.filters.comLancePresencial">
              <label class="no-select text-nowrap font-bold">
                <span class="m-l-xs text-nowrap">Com lances presencial</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="flex items-center" v-model="table.filters.ocultarFotos">
              <label class="no-select font-bold">
                <span class="m-l-xs text-nowrap">Ocultar fotos</span>
              </label>
            </erp-checkbox>
          </div>
        </div>
      </div>
      <div class="col" style="max-width: 140px">
        <erp-s-field
            class="font-10"
            view="lr"
            label="Buscar:"
        >
          <helper-input-btn>
            <erp-input simple-border v-model="table.filters.pesquisa" />
            <a class="m-l-xs text-black">IR</a>
          </helper-input-btn>
        </erp-s-field>
      </div>
      <div class="col m-l m-b-n" style="max-width: 110px;">
        <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8" />
      </div>
    </div>
    <div class="erp-list no-pad">
      <btn-acoes-lotes-selecionados
          v-if="table.selected.length > 0"
          @removerSelecionados="removerSelecionados"
          @removerLoteamento="removerLoteamentoSelecionados"
          @alterarStatus="alterarStatus"
          @copiarLotesSelecionados="SearchLeilao"
          class="m-b-xs"
      />
      <e-btn @click="$emit('adicionarSublotes', table.selected[0])" class="m-l-xs m-t-n-xs" v-if="table.selected.length === 1" label="Adicionar Sublotes" />
      <u-table
          ref="table"
          color="primary"
          :data="filteredServerData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[25, 50, 100, 200, 300, 500]"
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row)" class="cursor-pointer" slot="body" slot-scope="props"
              :props="props">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs" />
          </u-td>
          <u-td key="loteString" :props="props">
            <u-input v-on:dblclick.native.stop.prevent="void(0)" style="width: 30px" align="center" v-model="props.row.numeroString"
                     v-if="habilitarLoteamentoManual" />
            <div v-else class="font-bold">{{props.row.numeroString}}</div>
          </u-td>
          <u-td key="lote" :props="props">
            <u-input type="number" min="1" style="width: 30px" align="center" v-model="props.row.numero"
                     v-if="habilitarLoteamentoManual" />
            <div v-else class="font-bold">{{props.row.numero}}</div>
          </u-td>
          <u-td key="loteId" :props="props">
            {{props.row.id}}
          </u-td>
          <u-td key="bemId" :props="props">
            {{props.row.bem.id}}
          </u-td>
          <u-td key="placa" :props="props">
            {{props.row.bem.placa}}
          </u-td>
          <u-td key="comitente" :props="props" class="uppercase td-limit l-130" :title="props.row.bem.comitente && props.row.bem.comitente.pessoa.name">
            {{props.row.bem.comitente && props.row.bem.comitente.pessoa.name}}
          </u-td>
          <u-td v-if="!table.filters.ocultarFotos" key="imagem" :props="props">
            <img v-if="props.row.bem.image && props.row.bem.image.thumb && props.row.bem.image.thumb.url" :src="props.row.bem.image.thumb.url" width="50" />
          </u-td>
          <u-td :title="props.row.descricao" class="td-limit l-130 uppercase" key="descricao"
                :props="props">
            {{props.row.descricao}}
          </u-td>
          <u-td key="processo" :props="props">
            {{props.row.bem.processoNumero|formataNumeroProcesso}}
          </u-td>
          <u-td key="marca" :props="props">
            {{props.row.bem.cache && props.row.bem.cache.marca}}
          </u-td>
          <u-td key="modelo" :props="props">
            {{props.row.bem.cache && props.row.bem.cache.modelo}}
          </u-td>
          <u-td key="cor" :props="props">
            {{props.row.bem.cache && props.row.bem.cache.cor}}
          </u-td>
          <u-td key="ano" :props="props">
            {{props.row.bem.anoFabricacao}}/{{props.row.bem.anoModelo}}
          </u-td>
          <u-td key="combustivel" :props="props">
            {{props.row.bem.cache && props.row.bem.cache.combustivel}}
          </u-td>
          <u-td key="chassi" :props="props">
            {{props.row.bem.chassi}}
          </u-td>
          <u-td key="valorMercado" :props="props">
            R$ {{props.row.valorMercado|moeda}}
          </u-td>
          <u-td key="valorAvaliacao" :props="props">
            R$ {{props.row.valorAvaliacao|moeda}}
          </u-td>
          <u-td key="valorInicial" :props="props">
            R$ {{props.row.valorInicial|moeda}} <span v-if="props.row.valorInicial2 && leilao.instancia === 2">/ R$ {{props.row.valorInicial2|moeda}}</span>
          </u-td>
          <u-td key="valorMinimo" :props="props">
            R$ {{props.row.valorMinimo|moeda}}
          </u-td>
          <u-td key="arremate" :props="props">
            <span v-if="props.row.arremate">{{props.row.arremate.arrematante.apelido}}</span>
            <span v-else>-</span>
          </u-td>
          <u-td key="lances" :props="props">
            <div v-if="props.row.historicoLances"> <!-- TODO ? -->
              {{props.row.historicoLances.total}}
              <span v-if="props.row.historicoLances.atual" class="font-10 font-thin">
                (R$ {{props.row.historicoLances.atual.valor|moeda}})
              </span>
            </div>
            <div v-else-if="props.row.lances && props.row.lances.length">
              {{props.row.totalLances}}
              <span v-if="props.row.lances[0]" class="font-10 font-thin">
                (R$ {{props.row.lances[0].valor|moeda}})
              </span>
            </div>
            <div v-else>-</div>
          </u-td>
          <u-td class="text-uppercase" key="status" :props="props">
            <status-lote :lote="props.row" /> <!-- TODO: PENDENTE DE PAGAMENTO / PAGO -->
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <default-list-menu
                    @abrir="abrir(props.row)"
                    @abrirBem="abrirBem(props.row.bem.id)"
                    @removerLote="remover(props.row)"
                    :leilao="props.row" />
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <context-menu-lote-item
              @abrir="abrir(props.row)"
              @abrirBem="abrirBem(props.row.bem.id)"
              @removerLote="remover(props.row)"
              :leilao="props.row" />
        </u-tr>
      </u-table>

      <!--<u-btn v-if="table.selected.length > 0" @click="removerSelecionados" :disable="excluir.loading" :loading="excluir.loading" class="m-t" no-caps push color="negative" icon="times" icon-type="fa" size="sm" label="Remover lotes selecionados do leilão" />-->
      <btn-acoes-lotes-selecionados
          v-if="table.selected.length > 0"
          @removerSelecionados="removerSelecionados"
          @removerLoteamento="removerLoteamentoSelecionados"
          @alterarStatus="alterarStatus"
          @copiarLotesSelecionados="SearchLeilao"
          class="m-t-xs"
      />
      <div v-if="habilitarLoteamentoManual">
        <u-btn @click="atualizarLoteamento" :loading="loteamento.loading" class="m-t" no-caps push color="positive"
               icon="save" icon-type="fa" size="sm" label="Atualizar Loteamento" />
        <u-btn @click="() => {this.habilitarLoteamentoManual = false; this.load()}" class="m-t m-l" no-caps push
               color="grey" icon="times" icon-type="fa" size="sm" label="Cancelar " />
      </div>
    </div>
  </div>
</template>

<script>
import {UCheckbox, UTable, UTd, UTh, UTr, UInput, LocalStorage} from 'uloc-vue'
import {EBtnTableOptions, ErpCheckbox, ErpSField, ErpInput, HelperInputBtn} from 'uloc-vue-plugin-erp'
import {
  atualizaLoteamento,
  listLotes as list,
  removeLotes,
  copiarLotesParaOutroLeilao
} from '../../../../domain/leiloes/services'
import SearchLeilao from '../window/search/SearchLeilaoCopiaLotes'
import MenuOptions from '../../../layout/context-menu/context-window-options'
import DefaultListMenu from '../context/list-menu-lote'
import StatusLote from './StatusLote'
import ContextMenuLoteItem from '../context/list-context-lote'
import StatusLoteSelect from './StatusLoteSelect'
import loteWindow from '../../windows/lote'
import bemWindow from '../../../bem/windows/bem'
import BtnAcoesLotesSelecionados from '../context/BtnAcoesLotesSelecionados'
import AlteracaoStatusLoteMixin from './AlteracaoStatusLoteMixin'
import listOptions from '../../windows/listOptions'
import tableColumnsCache from "@/utils/tableColumnsCache"
const listName = 'leilao.lotes.list'
const listStorage = tableColumnsCache(listName, [
  {label: 'LT', name: 'loteString', active: false, sortable: true, ordering: 0},
  {label: 'LTn', name: 'lote', active: true, sortable: true, ordering: 1},
  {label: 'ID Lote', name: 'loteId', active: false, sortable: false, ordering: 1},
  {label: 'ID Bem', name: 'bemId', active: false, sortable: false, ordering: 1},
  {label: 'Placa', name: 'placa', active: false, sortable: true, ordering: 1},
  {label: 'Comitente', name: 'comitente', active: true, sortable: true, ordering: 1},
  {label: 'FT', name: 'imagem', active: true, sortable: true, ordering: 1, required: false},
  {label: 'Descrição', name: 'descricao', active: true, sortable: true, ordering: 1},
  {label: 'Processo', name: 'processo', active: false, sortable: true, ordering: 1},
  {label: 'Marca', name: 'marca', active: false, sortable: true, ordering: 1},
  {label: 'Modelo', name: 'modelo', active: false, sortable: true, ordering: 1},
  {label: 'Cor', name: 'cor', active: false, sortable: true, ordering: 1},
  {label: 'Ano', name: 'ano', active: false, sortable: true, ordering: 1},
  {label: 'Combustível', name: 'combustivel', active: false, sortable: true, ordering: 1},
  {label: 'Chassi', name: 'chassi', active: false, sortable: true, ordering: 1},
  {label: 'Merc/Fipe', name: 'valorMercado', active: true, sortable: true, ordering: 1},
  {label: 'Avaliação', name: 'valorAvaliacao', active: true, sortable: true, ordering: 1},
  {label: 'Inicial', name: 'valorInicial', active: true, sortable: true, ordering: 1},
  {label: 'Mínimo', name: 'valorMinimo', active: true, sortable: true, ordering: 1},
  {label: 'Arremate', name: 'arremate', active: true, sortable: true, ordering: 1},
  {label: 'Lances', name: 'lances', active: true, sortable: true, ordering: 1},
  {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1}
], 6)

let filters = {
  codigo: null,
  status: [],
  semLance: true,
  comLancePresencial: true,
  comLanceOnline: true,
  ocultarFotos: true,
  pesquisa: null
}

export default {
  name: 'ListaLotes',
  mixins: [AlteracaoStatusLoteMixin],
  props: {
    leilao: {required: true}
  },
  data () {
    return {
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 200
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            required: o.required || null,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      excluir: {
        loading: false
      },
      habilitarLoteamentoManual: false,
      loteamento: {
        loading: false
      }
    }
  },
  components: {
    BtnAcoesLotesSelecionados,
    StatusLoteSelect,
    ContextMenuLoteItem,
    StatusLote,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    DefaultListMenu,
    ErpCheckbox,
    ErpSField,
    ErpInput,
    HelperInputBtn,
    UInput
  },
  mounted () {
    this.load()
  },
  computed: {
    visibledColumns () {
      return this.listStorage.filter(item => {
        if (item.name === 'imagem') {
          return !this.table.filters.ocultarFotos
        }
        return item.active
      }).map(item => item.name)
    },
    filteredServerData () {
      let data = this.table.serverData.slice()
      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        console.log(this.table.filters.status)
        data = data.filter(lt => {
          return this.table.filters.status.includes(lt.status)
        })
      }

      // TODO: Sem lances
      // TODO: Com lances
      // TODO: Com lances presencial

      let busca = this.table.filters.pesquisa
      if (busca && String(busca).length > 0) {
        busca = String(busca).toLowerCase()
        data = data.filter(lt => {
          return String(lt.descricao).toLowerCase().indexOf(busca) > -1
              || String(lt.bem.placa).toLowerCase().indexOf(busca) > -1
              || String(lt.bem.chassi).toLowerCase().indexOf(busca) > -1
              || String(lt.bem.ano).toLowerCase().indexOf(busca) > -1
              || String(lt.numero) === busca
              || String(lt.bem.comitente.pessoa.name).toLowerCase().indexOf(busca) > -1
          // TODO: Implementar outros campos, principalmente arremate
        })
      }
      return data
      /*return this.table.serverData.slice().sort(function (a, b) {
        a = Number(a['numero'])
        b = Number(b['numero'])
        return (a === b ? 0 : a > b ? 1 : -1) * 1 // -1 = desc, 1 = asc
      })*/
    }
  },
  methods: {
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request ({pagination, filter}) {
      this.habilitarLoteamentoManual = false
      this.table.loading = true
      this.table.selected = []
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${filter}`)

      list(this.leilao.id, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result || []

            this.table.loading = false
            this.loadedData()
          })
          .catch(error => {
            this.loading = false
          })
    },
    abrir (lote) {
      this.loteWindow(lote)
    },
    abrirBem (id) {
      this.bemWindow(id)
    },
    remover (lote) {
      this.executaRemocaoLotes([lote.id])
    },
    removerSelecionados () {
      let lotes = this.table.selected
      this.executaRemocaoLotes(lotes.slice().map(lt => lt.id))
    },
    executaRemocaoLotes (lotes) {
      this.alertaRemocaoLotes()
          .then(() => {
            console.log('Removendo...')
            removeLotes(this.leilao.id, lotes)
                .then(response => {
                  this.load()
                  this.$uloc.dialog({
                    title: 'Sucesso!',
                    color: 'positive',
                    message: `Lote(s) removido(s) com sucesso.`
                  })
                })
                .catch(error => {
                  this.alertApiError(error)
                  console.log(error, error.data)
                })
          }).catch(() => {
      })
    },
    alertaRemocaoLotes () {
      return this.$uloc.dialog({
        title: 'Você está prestes a remover lotes do leilão',
        message: 'Para remover lotes do leilão, os mesmos não podem conter lances e o leilão não pode estar com status preparado. Nestes casos, atualize o status do lote para Retirado ou outro status que se enquadre. Tem certeza que deseja remover este(s) lote(s) ?',
        ok: 'Sim, remover do leilão',
        cancel: 'Não'
      })
    },
    loteWindow: loteWindow,
    bemWindow: bemWindow,
    listOptions: listOptions,
    loadedData () {
      this.$emit('loaded', this.table.serverData)
    },
    loteamentoManual () {
      console.log('Iniciando Loteamento Manual')
      this.habilitarLoteamentoManual = true
    },
    atualizarLoteamento (lotes = null) {
      console.log('Atualizando loteamento...')
      if (!lotes || !Array.isArray(lotes)) {
        lotes = this.filteredServerData.slice().map(lt => {
          return {id: lt.id, numero: lt.numero, numeroString: lt.numeroString}
        })
      }
      this.table.loading = true
      atualizaLoteamento(this.leilao.id, lotes)
          .then((response) => {
            this.table.loading = false
            this.load()
          })
          .catch(error => {
            this.table.loading = false
            this.alertApiError(error)
            console.log(error, error.data)
          })
    },
    removerLoteamentoSelecionados () {
      let lotes = this.table.selected
      this.$uloc.dialog({
        title: 'Remover loteamento',
        message: `Tem certeza que deseja remover o número do lote do(s) ${lotes.length} lote(s) selecionado(s) ?`,
        ok: 'Sim, remover loteamento',
        cancel: 'Não'
      })
          .then(() => {
            lotes = lotes.slice().map(lt => {
              return {id: lt.id, numero: null}
            })
            this.atualizarLoteamento(lotes)
          })
          .catch(() => {
          })
    },
    loteamentoAutomatico (ordering) {
      console.log('Loteando automaticamente por ' + ordering)
      this.$uloc.loading.show()
      let lotes = this.table.serverData.slice()
      if (ordering === 'comitente') { // TODO: Poder selecionar ordem dos comitentes a antes
        lotes = lotes.sort(function (a, b) {
          a = Number(a.bem.comitente.id)
          b = Number(b.bem.comitente.id)
          return (a === b ? 0 : a > b ? 1 : -1) * 1 // -1 = desc, 1 = asc
        })
      }

      if (ordering === 'tipo') { // TODO: Poder selecionar ordem antes
        lotes = this.table.serverData.slice().sort(function (a, b) {
          a = Number(a.bem.tipo.id)
          b = Number(b.bem.tipo.id)
          return (a === b ? 0 : a > b ? 1 : -1) * 1 // -1 = desc, 1 = asc
        })
      }

      if (ordering === 'valorCrescente' || ordering === 'valorDecrescente') {
        let sort = ordering === 'valorCrescente' ? 1 : -1
        lotes = this.table.serverData.slice().sort(function (a, b) {
          a = Number(a.valorAvaliacao)
          b = Number(b.valorAvaliacao)
          return (a === b ? 0 : a > b ? 1 : -1) * sort // -1 = desc, 1 = asc
        })
      }

      let numero = 0
      lotes = lotes.map(lt => {
        numero++
        return {
          ...lt,
          numero: numero
        }
      })
      this.table.serverData = lotes
      this.habilitarLoteamentoManual = true
      this.$uloc.loading.hide()
    },
    windowLoteEvents (wid) {
      this.$uloc.window.listen(wid, {
        update: (wid, lote) => {
          console.log('Updated lote event')
          // this.load()
          this.load()
        }
      })
    },
    updateListDefinition(newConfig) {
      LocalStorage.set(listName, newConfig)
      this.listStorage = newConfig
    },
    copiarLotesSelecionados (lotes) {
      this.$uloc.notify({
        color: 'warning',
        message: 'Copiando lotes, aguarde...'
      })
      copiarLotesParaOutroLeilao(this.leilao.id, lotes, this.leilaoSelecionado.id)
          .then(result => {
            this.$uloc.dialog({
              title: 'Sucesso!',
              color: 'positive',
              message: 'Lote(s) copiado(s) com sucesso.'
            })
            this.sucessoCopiaLotes = true
          })
          .catch(error => {
            this.alertApiError(error)
            this.leilaoSelecionado = null
          })
    },
    confirmaCopiarLotes () {
      let lotes = this.table.selected
      this.$uloc.dialog({
        title: 'Copiar lotes',
        message: `Tem certeza que deseja copiar o(s) ${lotes.length} lote(s) selecionado(s) para o leilão "${this.leilaoSelecionado.titulo}"?`,
        ok: 'Sim, copiar lotes',
        cancel: 'Cancelar'
      })
          .then(() => {
            lotes = lotes.map(lt => lt.id)
            this.copiarLotesSelecionados(lotes)
          })
          .catch(() => {
            this.leilaoSelecionado = null
          })
    },
    SearchLeilao
  }
}
</script>
